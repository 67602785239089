import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  getPrograms,
  profileGetDataService,
  profileUpdateDataService,
} from "../../services/dataService";
import { toast } from "react-toastify";
import { InfoIcon } from "lucide-react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
} from "@mui/material";
import aiml from "../../assets/aicte.pdf";
import apaar from "../../assets/APAAR ID (1).pdf";
import { Worker, Viewer } from "@react-pdf-viewer/core"; // For displaying the PDF
import "@react-pdf-viewer/core/lib/styles/index.css"; // Default styles

// Import the pdfjs-dist worker
import { Document, Page, pdfjs } from "react-pdf";
import { useNavigate } from "react-router-dom";
// import pdfWorker from "pdfjs-dist/build/pdf.worker.entry"; // PDF.js worker entry

// Set the worker for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 900,
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   p: 4,
// };

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Make the width flexible
  maxWidth: 1000, // Set a maximum width for larger screens
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto", // Allow vertical scrolling
  maxHeight: "90vh", // Make sure the modal doesn't exceed the viewport height
};

const PersonalDetailsForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue, // To programmatically set the value of WhatsApp number
  } = useForm();

  const [isPermanentSame, setIsPermanentSame] = useState(false);

  const [showDisabilityOptions, setShowDisabilityOptions] = useState(false);
  const [showOtherDisability, setShowOtherDisability] = useState(false); // State to control "Other" input visibility
  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [branches, setBranches] = useState([]);
  const [showBranchFields, setShowBranchFields] = useState(false);
  const [selectedProgramDuration, setSelectedProgramDuration] = useState(null);
  const [selectedDisabilities, setSelectedDisabilities] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false); // Modal state for AICTE and APAAR info
  const [pdfUrl, setPdfUrl] = useState(""); // PDF URL state
  const [numPages, setNumPages] = useState(null);
  const navigate = useNavigate();
  const [jobStatus, setJobStatus] = useState(null);
  const [reasonForNoJob, setReasonForNoJob] = useState(null);
  const [isAlumni, setIsAlumni] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showUGBranchFields, setShowUGBranchFields] = useState(false);
  const [showPGBranchFields, setShowPGBranchFields] = useState(false);

  const semesters = ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th"];

  const admissionYear = watch("year_of_addmission");

  // Watch the value of the radio button for showing/hiding checkboxes
  const disabilityStatus = watch("is_pwd");

  // Watch the value of mobile and WhatsApp number
  const mobileNumber = watch("mobile");
  const isWhatsAppSame = watch("isWhatsAppSame");

  const handleOpenModal = (pdfFile) => {
    setPdfUrl(pdfFile); // Set the appropriate PDF URL
    setModalIsOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setModalIsOpen(false); // Close the modal
    setPdfUrl(""); // Clear the PDF URL
  };

  // Handle "Same as Mobile" checkbox change
  const handleWhatsAppSameChange = (e) => {
    if (e.target.checked) {
      setValue("whatsapp", mobileNumber);
    } else {
      setValue("whatsapp", "");
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
    "Ladakh",
    "Jammu and Kashmir",
  ];

  // Disability categories array including "Other"
  const disabilityOptions = [
    { id: "blindness", label: "Blindness and Low Vision" },
    { id: "deaf", label: "Deaf and Hard of Hearing" },
    {
      id: "locomotor & cerebral palsy",
      label: "Locomotor Disability including cerebral palsy",
    },
    { id: "intellectual", label: "Intellectual Disability" },
    { id: "multiple", label: "Multiple Disabilities" },
    { id: "other", label: "Other (Please specify)" }, // "Other" option added
  ];

  const handleDisabilityChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedDisabilities((prev) => [...prev, value]);
    } else {
      setSelectedDisabilities((prev) => prev.filter((item) => item !== value));
    }
  };

  // Regular expressions
  const aparIdRegex = /^\d{12}$/;
  const aicteIdRegex = /^STU[0-9a-zA-Z]{13,}$/;

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await profileGetDataService.profileData(); // Fetch the data from your service
        const data = response?.data;
        console.log(response?.data);

        if (data?.profile_update_status === 1) {
          navigate("/internship");
          return; // Don't continue with setting the profile form data
        }

        // Populate form fields with the initial data
        setValue("first_name", data?.first_name);
        setValue("middle_name", data.middle_name);
        setValue("last_name", data.last_name);
        setValue("email", data.email);
        setValue("mobile", data.mobile);
        setValue("whatsapp", data.whatsapp);
        setValue("dob", data.dob);
        setValue("gender", data.gender);
        setValue("caste", data.caste);
        setValue("disability", data.disability);
        setValue("state_id", data.state_id);
        setValue("district", data.district);
        setValue("city", data.city);
        setValue("pincode", data.pincode);
        setValue("address", data.address);
        setValue("institute_id", data.institute_id);
        setValue("tenth_mark", data.tenth_mark);
        setValue("tenthPassoutYear", data.tenthPassoutYear);
        setValue("intermediate_qualification", data.intermediate_qualification);
        setValue("twth_mark", data.twth_mark);
        setValue("roll_no", data.roll_no);
        setValue("aicteId", data.aicte_id);
        setValue("btech_cgpa", data.btech_cgpa);
        setValue("branch", data.branch);
        setIsAlumni(data?.is_alumini);
        setUpdateStatus(data?.profile_update_status);
        setIsLoading(false);
        // Set other fields as needed
      } catch (error) {
        console.error("Failed to fetch profile data:", error);
        setIsLoading(false); // In case of error, stop loading
      }
    };

    fetchProfileData();
  }, [setValue, navigate]); // Run once on component mount

  const disableRightClick = (e) => {
    e.preventDefault();
  };

  //   if (updateStatus === 1) {
  //     navigate("/internship");
  //   }

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await getPrograms.programsData(); // Fetch programs
        setPrograms(response.data); // Assuming response.data contains the array of programs
        console.log("Programs Data: ", response.data);
      } catch (error) {
        console.error("Failed to fetch programs", error);
      }
    };

    fetchPrograms();
  }, []);

  // Handle course selection to display branches
  const handleProgramChange = (e) => {
    const programId = e.target.value;
    const selectedProgramData = programs.find(
      (p) => String(p.program_id) === programId
    );

    console.log(selectedProgramData);

    if (selectedProgramData) {
      setBranches(selectedProgramData.branches);
      setSelectedProgramDuration(selectedProgramData.duration); // Store the program duration
      setValue("program_id", programId);
      setShowBranchFields(false); // Reset branch-related fields
    } else {
      setBranches([]);
      setSelectedProgramDuration(null);
      setShowBranchFields(false);
    }
  };

  const handleUGProgramChange = (e) => {
    const programId = e.target.value;
    const selectedProgramData = programs.find(
      (p) => String(p.program_id) === programId
    );

    if (selectedProgramData) {
      setBranches(selectedProgramData.branches);
      setValue("program_id_ug", programId);
      setSelectedProgramDuration(selectedProgramData.duration);
      setShowUGBranchFields(true);
    } else {
      setBranches([]);
      setSelectedProgramDuration(null);
      setShowUGBranchFields(false);
    }
  };

  const handlePGProgramChange = (e) => {
    const programId = e.target.value;
    const selectedProgramData = programs.find(
      (p) => String(p.program_id) === programId
    );

    if (selectedProgramData) {
      setBranches(selectedProgramData.branches);
      setValue("program_id_pg", programId);
      setSelectedProgramDuration(selectedProgramData.duration);
      setShowPGBranchFields(true);
    } else {
      setBranches([]);
      setSelectedProgramDuration(null);
      setShowPGBranchFields(false);
    }
  };

  // Handle branch selection to display Roll No. and University Regd. No.
  const handleBranchChange = (e) => {
    if (e.target.value) {
      setShowBranchFields(true);
    } else {
      setShowBranchFields(false);
    }
  };

  // const calculatePassoutYear = () => {
  //   if (admissionYear && selectedProgramDuration) {
  //     return parseInt(admissionYear) + parseInt(selectedProgramDuration);
  //   }
  //   return "";
  // };

  const ugAdmissionYear = watch("year_of_addmission_ug");
  const calculateUGPassoutYear = () => {
    if (ugAdmissionYear && selectedProgramDuration) {
      return parseInt(ugAdmissionYear) + parseInt(selectedProgramDuration);
    }
    return "";
  };

  const pgAdmissionYear = watch("year_of_addmission_pg");
  const calculatePGPassoutYear = () => {
    if (pgAdmissionYear && selectedProgramDuration) {
      return parseInt(pgAdmissionYear) + parseInt(selectedProgramDuration);
    }
    return "";
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    try {
      // Function to convert the file to a Base64 string
      const toBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result.split(",")[1]); // Get only the Base64 part
          reader.onerror = (error) => reject(error);
        });
      };

      // Check if a file is uploaded
      let profilePicBase64 = null;
      if (data.profile_pic && data.profile_pic.length > 0) {
        profilePicBase64 = await toBase64(data.profile_pic[0]); // Convert to Base64
      }

      // Prepare the payload
      const payload = {
        ...data,
        profile_pic: profilePicBase64,
        ug_passout_year: calculateUGPassoutYear().toString(), // Include UG passout year
        pg_passout_year: calculatePGPassoutYear().toString(), // Include PG passout year
        mobile: String(data.mobile),
        // job_status: jobStatus,
        // reason_for_no_job: reasonForNoJob, // Add Base64 string of the file to the payload
      };

      // Conditionally include job details only if isAlumni is "1"
      if (isAlumni === "1") {
        payload.job_status = jobStatus;
        payload.reason_for_no_job = reasonForNoJob;
      }

      // Convert selected disabilities to a string
      let disabilityString = selectedDisabilities.join(", ");

      // If "Other" is selected, append the specified disability
      if (selectedDisabilities.includes("other") && data.otherDisability) {
        disabilityString += `, ${data.otherDisability}`;
      }

      // Add the disability string to the payload
      payload.disability_types = disabilityString;

      // Send the payload using Axios or Fetch
      const response = await profileUpdateDataService.updateProfile(payload);

      if (response.status === 200) {
        toast.success("Profile updated successfully!");
        navigate("/internship");
      } else {
        throw new Error("Failed to update profile");
      }
    } catch (error) {
      toast.error("Failed to update profile. Please try again.");
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const LoadingIcon = () => (
    <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen p-4">
      <div className="max-w-8xl mx-auto p-4">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
          {/* Personal Details Card */}
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h2 className="text-xl uppercase font-semibold text-gray-700 bg-gray-300 px-2 py-2 mb-6 border-b-2 pb-2">
              Personal Details
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label className="block mb-1 font-medium">
                  First Name <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  style={{ textTransform: "uppercase" }} // This makes it display in uppercase
                  {...register("first_name", { required: true })}
                  onChange={(e) => {
                    e.target.value = e.target.value.toUpperCase();
                  }}
                />
                {errors.first_name && (
                  <p className="text-red-500">First name is required</p>
                )}
              </div>

              <div>
                <label className="block mb-1 font-medium">Middle Name</label>
                <input
                  style={{ textTransform: "uppercase" }}
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  {...register("middle_name")}
                  onChange={(e) => {
                    e.target.value = e.target.value.toUpperCase();
                  }}
                />
              </div>

              <div>
                <label className="block mb-1 font-medium">
                  Last Name <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  style={{ textTransform: "uppercase" }}
                  {...register("last_name", { required: true })}
                  onChange={(e) => {
                    e.target.value = e.target.value.toUpperCase();
                  }}
                />
                {errors.last_name && (
                  <p className="text-red-500">Last name is required</p>
                )}
              </div>
            </div>

            {/* Email, Mobile, WhatsApp on one line */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
              <div>
                <label className="block mb-1 font-medium">
                  Email ID <span className="text-red-500">*</span>
                </label>
                <input
                  className={`w-full border p-2 rounded-lg ${
                    errors.email ? "border-red-500" : "border-gray-300"
                  }`}
                  type="email"
                  readOnly={isAlumni === "1" ? true : false}
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Please enter a valid email address",
                    },
                  })}
                />
                {errors.email && (
                  <p className="text-red-500">{errors.email.message}</p>
                )}
              </div>

              <div>
                <label className="block mb-1 font-medium">
                  Contact No. <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  type="text" // Ensure the input type is text to handle numbers with leading zeros
                  {...register("mobile", {
                    required: "Mobile number is required",
                    pattern: {
                      value: /^[6-9]\d{9}$/, // Validate the mobile number pattern
                      message: "Invalid mobile number format",
                    },
                  })}
                />
                {errors.mobile && (
                  <p className="text-red-500">{errors.mobile.message}</p>
                )}
              </div>

              <div>
                <label className="block mb-1 font-medium">
                  WhatsApp No.<span className="text-red-500">*</span>
                </label>

                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  type="text"
                  {...register("whatsapp", {
                    required: !isWhatsAppSame && "WhatsApp number is required", // Required only if not disabled
                    pattern: {
                      value: /^[6-9]\d{9}$/,
                      message: "Invalid WhatsApp number format",
                    },
                  })}
                  disabled={isWhatsAppSame} // Disable input if checkbox is checked
                />
                {errors.whatsapp && (
                  <p className="text-red-500">{errors.whatsapp.message}</p>
                )}
                {/* Checkbox for Same as Mobile - Placed directly under WhatsApp label */}
                <div className="flex items-center mt-1 ml-2">
                  <input
                    type="checkbox"
                    {...register("isWhatsAppSame")}
                    onChange={handleWhatsAppSameChange}
                  />
                  <label className="ml-2 font-medium">
                    Same as contact number
                  </label>
                </div>
              </div>
            </div>

            {/* Checkbox for Same as Mobile */}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              <div>
                <label className="block mb-1 font-medium">
                  AICTE Internship ID <span className="text-red-500">*</span>
                </label>
                <div className="flex items-center">
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    {...register("aicteId", {
                      required: "AICTE ID is required",
                      pattern: {
                        value: aicteIdRegex,
                        message: "Invalid AICTE Internship ID",
                      },
                    })}
                  />
                  <IconButton onClick={() => handleOpenModal(aiml)}>
                    <InfoIcon />
                  </IconButton>
                </div>
                {errors.aicteId && (
                  <p className="text-red-500">{errors.aicteId.message}</p>
                )}
              </div>

              <div>
                <label className="block mb-1 font-medium">
                  APAAR ID (Optional)
                </label>
                <div className="flex items-center">
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    {...register("apar_id", {
                      pattern: {
                        value: aparIdRegex,
                        message: "Invalid APAAR ID. Must be exactly 12 digits.",
                      },
                    })}
                  />
                  {/* <button className="ml-2 text-blue-600">i</button> */}
                  <IconButton onClick={() => handleOpenModal(apaar)}>
                    <InfoIcon />
                  </IconButton>
                </div>
                {errors.apar_id && (
                  <p className="text-red-500">{errors.apaar_id.message}</p>
                )}
              </div>
            </div>

            {/* DOB, Gender, Category, Are you a person with any disability */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
              <div>
                <label className="block mb-1 font-medium">
                  Date of Birth <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  type="date"
                  {...register("dob", { required: true })}
                />
                {errors.dob && <p className="text-red-500">DOB is required</p>}
              </div>

              <div>
                <label className="block mb-1 font-medium">
                  Gender <span className="text-red-500">*</span>
                </label>
                <select
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  {...register("gender", { required: true })}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Transgender</option>
                </select>
                {errors.gender && (
                  <p className="text-red-500">Gender is required</p>
                )}
              </div>

              <div>
                <label className="block mb-1 font-medium">
                  Category <span className="text-red-500">*</span>
                </label>
                <select
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  {...register("caste", { required: true })}
                >
                  <option value="General">General</option>
                  <option value="OBC">OBC</option>
                  <option value="SC/ST">SC</option>
                  <option value="SC/ST">ST</option>
                </select>
                {errors.caste && (
                  <p className="text-red-500">Category is required</p>
                )}
              </div>

              {/* Are you a person with any disability (Radio Button) */}
              {/* Are you a person with any disability (Radio Button) */}
              <div className="mt-4">
                <label className="block mb-1 font-medium">
                  Are you a person with any disability?{" "}
                  <span className="text-red-500">*</span>
                </label>
                <div className="flex items-center space-x-4">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      value="yes"
                      {...register("is_pwd", { required: true })} // Use the same 'register' field
                      className="form-radio"
                      onClick={() => setShowDisabilityOptions(true)}
                    />
                    <span className="ml-2">Yes</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      value="no"
                      {...register("is_pwd", { required: true })} // Use the same 'register' field
                      className="form-radio"
                      onClick={() => setShowDisabilityOptions(false)}
                    />
                    <span className="ml-2">No</span>
                  </label>
                </div>
                {errors.is_pwd && (
                  <p className="text-red-500">
                    Please select if you have a disability
                  </p>
                )}
              </div>
            </div>

            {/* Disability Categories (Checkboxes - Only show if "Yes" is selected) */}
            {disabilityStatus === "yes" && (
              <div className="mt-4">
                <label className="block mb-2 font-medium">
                  If yes, please select applicable categories:
                </label>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {disabilityOptions.map((option) => (
                    <label key={option.id} className="inline-flex items-center">
                      <input
                        type="checkbox"
                        value={option.id}
                        onChange={handleDisabilityChange}
                        className="form-checkbox"
                      />
                      <span className="ml-2">{option.label}</span>
                    </label>
                  ))}
                </div>

                {selectedDisabilities.includes("other") && (
                  <div className="mt-4">
                    <label className="block mb-2 font-medium">
                      Please specify your disability:{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      className="w-full border border-gray-300 p-2 rounded-lg"
                      {...register("otherDisability", {
                        required: selectedDisabilities.includes("other"),
                      })}
                    />
                    {errors.otherDisability && (
                      <p className="text-red-500">
                        Please specify your disability
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}

            <h1 className="text-xl font-bold mt-6 uppercase text-gray-700 px-2 py-2 mb-3 pb-2">
              Residential / Permanent Address :
            </h1>

            {/* Address, Family Annual Income */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
              <div>
                <label className="block mb-1 font-medium">
                  Address <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  type="text"
                  {...register("house_no", { required: true })}
                />
                {errors.house_no && (
                  <p className="text-red-500">Address is required</p>
                )}
              </div>
              <div>
                <label className="block mb-1 font-medium">
                  Village/Town/City <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  type="text"
                  {...register("city", { required: true })}
                />
                {errors.city && (
                  <p className="text-red-500">Village/Town/City is required</p>
                )}
              </div>

              <div>
                <label className="block mb-1 font-medium">
                  District <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  type="text"
                  {...register("street_name", { required: true })}
                />
                {errors.street_name && (
                  <p className="text-red-500">District is required</p>
                )}
              </div>
              <div>
                <label className="block mb-1 font-medium">
                  State <span className="text-red-500">*</span>
                </label>
                <select
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  {...register("state", { required: true })}
                >
                  <option value="">Select State</option>
                  {indianStates.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
                {errors.state && (
                  <p className="text-red-500">State is required</p>
                )}
              </div>
              <div>
                <label className="block mb-1 font-medium">
                  Pincode <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  type="text"
                  {...register("pincode", {
                    required: true,
                    pattern: /^[0-9]{6}$/,
                  })}
                />
                {errors.pincode && errors.pincode.type === "required" && (
                  <p className="text-red-500">Pincode is required</p>
                )}
                {errors.pincode && errors.pincode.type === "pattern" && (
                  <p className="text-red-500">
                    Please enter a valid 6-digit pincode
                  </p>
                )}
              </div>
            </div>

            {/* AICTE Internship ID (PDF), APAAR ID */}
          </div>

          {/* Academy Details Card */}
          {/* Academy Details Card */}
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h2 className="text-xl uppercase font-semibold text-gray-700 bg-gray-300 px-2 py-2 mb-6 border-b-2 pb-2">
              Academy Details
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
              {/* <div>
                <h2 className="text-xl font-bold border-b-2 text-gray-700">
                  10th Qualification Details:
                </h2>
              </div> */}
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div>
                  <label className=" block  mb-1 font-medium">
                    Qualification Details{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    placeholder="10th Details:"
                    disabled
                  />
                </div>
                {/* Institute Name */}
                <div>
                  <label className="block mb-1 font-medium">
                    School Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    {...register("school_name_tenth", { required: true })}
                  />
                  {errors.school_name_tenth && (
                    <p className="text-red-500">School Name is required</p>
                  )}
                </div>

                {/* 10th State */}
                {/* <div>
                  <label className="block mb-1 font-medium">State</label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="text"
                    {...register("tenth_state", { required: true })}
                  />
                  {errors.tenth_state && (
                    <p className="text-red-500">State is required</p>
                  )}
                </div> */}

                {/* 10th Mark */}
                <div>
                  <label className="block mb-1 font-medium">
                    10th Mark (%) <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    type="number"
                    {...register("tenth_mark", {
                      required: true,
                      min: 0,
                      max: 100,
                    })}
                  />
                  {errors.tenth_mark && (
                    <p className="text-red-500">
                      Please enter a valid percentage
                    </p>
                  )}
                </div>

                {/* 10th Passout Year */}
                <div>
                  <label className="block mb-1 font-medium">
                    10th Passout Year <span className="text-red-500">*</span>
                  </label>
                  <select
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    {...register("matric_passout_year", { required: true })}
                  >
                    <option value="">Select Year</option>
                    {Array.from(
                      { length: 30 },
                      (_, i) => new Date().getFullYear() - i
                    ).map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  {errors.matric_passout_year && (
                    <p className="text-red-500">Passout year is required</p>
                  )}
                </div>
              </div>

              {/* 10th Passout Year
              <div>
                <label className="block mb-1 font-medium">
                  10th Passout Year
                </label>
                <select
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  {...register("tenthPassoutYear", { required: true })}
                >
                  <option value="">Select Year</option>
                  {Array.from(
                    { length: 30 },
                    (_, i) => new Date().getFullYear() - i
                  ).map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                {errors.tenthPassoutYear && (
                  <p className="text-red-500">Passout year is required</p>
                )}
              </div> */}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4 border-b-2 pb-4 md:border-0 md:pb-0">
              {/* Select Intermediate Qualification Dropdown (col-1) */}
              <div className="col-span-1">
                <label className="block mb-1 font-medium">
                  Intermediate Qualification{" "}
                  <span className="text-red-500">*</span>
                </label>
                <select
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  {...register("intermediate_qualification", {
                    required: true,
                  })}
                >
                  <option value="">Select Intermediate Qualification</option>
                  <option value="12th">12th</option>
                  <option value="Diploma">Diploma</option>
                  <option value="ITI">ITI</option>
                </select>
                {errors.intermediate_qualification && (
                  <p className="text-red-500">Qualification is required</p>
                )}
              </div>

              {/* Conditional Fields Based on Qualification (col-5) */}
              <div className="md:col-span-3">
                {watch("intermediate_qualification") === "12th" && (
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {/* Institute Name for 12th */}
                    <div>
                      <label className="block mb-1 font-medium">
                        Institute Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        type="text"
                        {...register("school_name_twlth", { required: true })}
                      />
                      {errors.school_name_twlth && (
                        <p className="text-red-500">
                          Institute Name is required
                        </p>
                      )}
                    </div>

                    {/* State for 12th */}
                    {/* <div>
                      <label className="block mb-1 font-medium">State</label>
                      <input
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        type="text"
                        {...register("twth_state", { required: true })}
                      />
                      {errors.twth_state && (
                        <p className="text-red-500">State is required</p>
                      )}
                    </div> */}

                    {/* 12th Percentage */}
                    <div>
                      <label className="block mb-1 font-medium">
                        12th Percentage <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        type="number"
                        {...register("twth_mark", {
                          required: true,
                          min: 0,
                          max: 100,
                        })}
                      />
                      {errors.twth_mark && (
                        <p className="text-red-500">
                          Please enter a valid percentage
                        </p>
                      )}
                    </div>

                    {/* 12th Passout Year */}
                    <div>
                      <label className="block mb-1 font-medium">
                        12th Passout Year{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <select
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        {...register("intermediate_passout_year", {
                          required: true,
                        })}
                      >
                        <option value="">Select Year</option>
                        {Array.from(
                          { length: 30 },
                          (_, i) => new Date().getFullYear() - i
                        ).map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                      {errors.intermediate_passout_year && (
                        <p className="text-red-500">Passout year is required</p>
                      )}
                    </div>
                  </div>
                )}

                {watch("intermediate_qualification") === "Diploma" && (
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {/* Institute Name for Diploma */}
                    <div>
                      <label className="block mb-1 font-medium">
                        Institute Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        type="text"
                        {...register("school_name_twlth", {
                          required: true,
                        })}
                      />
                      {errors.school_name_twlth && (
                        <p className="text-red-500">
                          Institute Name is required
                        </p>
                      )}
                    </div>

                    {/* State for Diploma */}
                    {/* <div>
                      <label className="block mb-1 font-medium">State</label>
                      <input
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        type="text"
                        {...register("diploma_state", { required: true })}
                      />
                      {errors.diploma_state && (
                        <p className="text-red-500">State is required</p>
                      )}
                    </div> */}

                    {/* Diploma Percentage */}
                    <div>
                      <label className="block mb-1 font-medium">
                        Diploma Percentage{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        type="number"
                        {...register("twth_mark", {
                          required: true,
                          min: 0,
                          max: 100,
                        })}
                      />
                      {errors.twth_mark && (
                        <p className="text-red-500">
                          Please enter a valid percentage
                        </p>
                      )}
                    </div>

                    {/* Diploma Passout Year */}
                    <div>
                      <label className="block mb-1 font-medium">
                        Diploma Passout Year{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <select
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        {...register("intermediate_passout_year", {
                          required: true,
                        })}
                      >
                        <option value="">Select Year</option>
                        {Array.from(
                          { length: 30 },
                          (_, i) => new Date().getFullYear() - i
                        ).map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                      {errors.intermediate_passout_year && (
                        <p className="text-red-500">Passout year is required</p>
                      )}
                    </div>
                  </div>
                )}

                {watch("intermediate_qualification") === "ITI" && (
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {/* Institute Name for ITI */}
                    <div>
                      <label className="block mb-1 font-medium">
                        Institute Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        type="text"
                        {...register("school_name_twlth", { required: true })}
                      />
                      {errors.school_name_twlth && (
                        <p className="text-red-500">
                          Institute Name is required
                        </p>
                      )}
                    </div>

                    {/* State for ITI */}
                    {/* <div>
                      <label className="block mb-1 font-medium">State</label>
                      <input
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        type="text"
                        {...register("iti_state", { required: true })}
                      />
                      {errors.iti_state && (
                        <p className="text-red-500">State is required</p>
                      )}
                    </div> */}

                    {/* ITI Percentage */}
                    <div>
                      <label className="block mb-1 font-medium">
                        ITI Percentage <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        type="number"
                        {...register("mark_twlth", {
                          required: true,
                          min: 0,
                          max: 100,
                        })}
                      />
                      {errors.mark_twlth && (
                        <p className="text-red-500">
                          Please enter a valid percentage
                        </p>
                      )}
                    </div>

                    {/* ITI Passout Year */}
                    <div>
                      <label className="block mb-1 font-medium">
                        ITI Passout Year <span className="text-red-500">*</span>
                      </label>
                      <select
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        {...register("intermediate_passout_year", {
                          required: true,
                        })}
                      >
                        <option value="">Select Year</option>
                        {Array.from(
                          { length: 30 },
                          (_, i) => new Date().getFullYear() - i
                        ).map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                      {errors.intermediate_passout_year && (
                        <p className="text-red-500">Passout year is required</p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* <div className="bg-white shadow-lg rounded-lg p-6">
              <h2 className="text-xl uppercase font-semibold text-gray-700 bg-gray-300 px-2 py-2 mb-6 border-b-2 pb-2">
                UG Details
              </h2>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block mb-1 font-medium">
                    Select UG Course <span className="text-red-500">*</span>
                  </label>
                  <select
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    onChange={handleUGProgramChange}
                  >
                    <option value="">Select UG Course</option>
                    {programs.map((program) => (
                      <option
                        key={program.program_id}
                        value={program.program_id}
                      >
                        {program.program_name}
                      </option>
                    ))}
                  </select>
                  <input
                    type="hidden"
                    {...register("program_id_ug", { required: true })}
                  />
                  {errors.program_id_ug && (
                    <p className="text-red-500">
                      UG Course selection is required
                    </p>
                  )}
                </div>

                
                {showUGBranchFields && (
                  <div>
                    <label className="block mb-1 font-medium">
                      Select UG Branch <span className="text-red-500">*</span>
                    </label>
                    <select
                      className="w-full border border-gray-300 p-2 rounded-lg"
                      {...register("branch_ug", { required: true })}
                      onChange={(e) =>
                        handleBranchChange(e, setShowUGBranchFields)
                      }
                    >
                      <option value="">Select UG Branch</option>
                      {branches.map((branch, index) => (
                        <option key={index} value={branch}>
                          {branch}
                        </option>
                      ))}
                    </select>
                    {errors.branch_ug && (
                      <p className="text-red-500">UG Branch is required</p>
                    )}
                  </div>
                )}
              </div>

              
            </div> */}

            <h2 className="text-lg mb-2 mt-4 font-bold">UG:</h2>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
              {/* New fields start here */}
              <div>
                <label className="block mb-1 font-medium">
                  University Name <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  type="text"
                  {...register("institute_id", { required: true })}
                  readOnly
                />
                {errors.institute_id && (
                  <p className="text-red-500">University Name is required</p>
                )}
              </div>

              <div>
                <label className="block mb-1 font-medium">
                  College Name<span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  type="text"
                  {...register("college_name", { required: true })}
                />
                {errors.college_name && (
                  <p className="text-red-500">College Name is required</p>
                )}
              </div>

              <div>
                <label className="block mb-1 font-medium">
                  State<span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  type="text"
                  {...register("state_id", { required: true })}
                  readOnly
                />
                {errors.state_id && (
                  <p className="text-red-500">State is required</p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-8 gap-4 mt-4">
              {/* Select Course */}
              <div>
                <label className="block mb-1 font-medium">
                  Select UG Course <span className="text-red-500">*</span>
                </label>
                <select
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  onChange={handleUGProgramChange}
                >
                  <option value="">Select UG Course</option>
                  {programs.map((program) => (
                    <option key={program.program_id} value={program.program_id}>
                      {program.program_name}
                    </option>
                  ))}
                </select>
                <input
                  type="hidden"
                  {...register("program_id_ug", { required: true })}
                />
                {errors.program_id_ug && (
                  <p className="text-red-500">
                    UG Course selection is required
                  </p>
                )}
              </div>

              {/* Select Branch */}
              {showUGBranchFields && (
                <div>
                  <label className="block mb-1 font-medium">
                    Select UG Branch <span className="text-red-500">*</span>
                  </label>
                  <select
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    {...register("branch_ug", { required: true })}
                    onChange={(e) =>
                      handleBranchChange(e, setShowUGBranchFields)
                    }
                  >
                    <option value="">Select UG Branch</option>
                    {branches.map((branch, index) => (
                      <option key={index} value={branch}>
                        {branch}
                      </option>
                    ))}
                  </select>
                  {errors.branch_ug && (
                    <p className="text-red-500">UG Branch is required</p>
                  )}
                </div>
              )}

              <div>
                <label className="block mb-1 font-medium">
                  Select Semester <span className="text-red-500">*</span>
                </label>
                <select
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  {...register("semister", { required: true })}
                >
                  <option value="">Select Semester</option>
                  {semesters.map((semester, index) => (
                    <option key={index} value={semester}>
                      {semester}
                    </option>
                  ))}
                </select>
                {errors.semister && (
                  <p className="text-red-500">Semester selection is required</p>
                )}
              </div>

              <div>
                <label className="block mb-1 font-medium">
                  Roll No. <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  type="text"
                  {...register("roll_no", { required: true })}
                />
                {errors.roll_no && (
                  <p className="text-red-500">Roll No. is required</p>
                )}
              </div>

              <div>
                <label className="block mb-1 font-medium">
                  University Reg. No.<span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  type="text"
                  {...register("regd_no", { required: true })}
                />
                {errors.regd_no && (
                  <p className="text-red-500">
                    University Regd. No. is required
                  </p>
                )}
              </div>

              <div>
                <label className="block mb-1 font-medium">
                  CGPA <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  type="number"
                  step="0.01" // Allows decimal values
                  {...register("btech_cgpa", {
                    required: "CGPA is required",
                    min: {
                      value: 0,
                      message: "CGPA cannot be less than 0",
                    },
                    max: {
                      value: 10,
                      message: "CGPA cannot be more than 10",
                    },
                  })}
                />
                {errors.btech_cgpa && (
                  <p className="text-red-500">{errors.btech_cgpa.message}</p>
                )}
              </div>

              <div>
                <label className="block mb-1 font-medium">
                  Admission Year <span className="text-red-500">*</span>
                </label>
                <select
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  {...register("year_of_addmission_ug", { required: true })}
                >
                  <option value="">
                    Select Admission Year{" "}
                    <span className="text-red-500">*</span>
                  </option>
                  {Array.from(
                    { length: 30 },
                    (_, i) => new Date().getFullYear() - i
                  ).map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                {errors.year_of_addmission_ug && (
                  <p className="text-red-500">Admission Year is required</p>
                )}
              </div>

              <div>
                <label className="block mb-1 font-medium">
                  Passout Year (UG) <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  type="text"
                  value={calculateUGPassoutYear()} // Display calculated passout year for UG
                  readOnly
                />
              </div>
              {/* Hidden input to send passout year for UG */}
              <input
                type="hidden"
                {...register("ug_passout_year")}
                value={calculateUGPassoutYear()}
              />
            </div>

            {/* PG Section */}

            <h2 className="text-xl uppercase mt-4 font-semibold text-gray-700   py-2 mb-6 pb-2">
              PG Details
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
              {/* New fields start here */}
              <div>
                <label className="block mb-1 font-medium">
                  University Name
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  type="text"
                  {...register("pg_institute_id")}
                />
                {errors.pg_institute_id && (
                  <p className="text-red-500">University Name is required</p>
                )}
              </div>

              <div>
                <label className="block mb-1 font-medium">College Name</label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  type="text"
                  {...register("pg_college_name")}
                />
                {errors.pg_college_name && (
                  <p className="text-red-500">College Name is required</p>
                )}
              </div>

              <div>
                <label className="block mb-1 font-medium">State</label>
                <select
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  {...register("pg_state_id")}
                >
                  <option value="">Select State</option>
                  {indianStates.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
                {errors.pg_state_id && (
                  <p className="text-red-500">State is required</p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-8 gap-4 mt-4">
              {/* Select PG Course */}
              <div>
                <label className="block mb-1 font-medium">
                  Select PG Course
                </label>
                <select
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  onChange={handlePGProgramChange}
                >
                  <option value="">Select PG Course</option>
                  {programs.map((program) => (
                    <option key={program.program_id} value={program.program_id}>
                      {program.program_name}
                    </option>
                  ))}
                </select>
                <input type="hidden" {...register("program_id_pg")} />
              </div>

              {/* Select PG Branch */}
              {showPGBranchFields && (
                <div>
                  <label className="block mb-1 font-medium">
                    Select PG Branch
                  </label>
                  <select
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    {...register("branch_pg")}
                  >
                    <option value="">Select PG Branch</option>
                    {branches.map((branch, index) => (
                      <option key={index} value={branch}>
                        {branch}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* Select Semester */}
              <div>
                <label className="block mb-1 font-medium">
                  Select Semester
                </label>
                <select
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  {...register("semester_pg")}
                >
                  <option value="">Select Semester</option>
                  {semesters.map((semester, index) => (
                    <option key={index} value={semester}>
                      {semester}
                    </option>
                  ))}
                </select>
              </div>

              {/* Roll No. */}
              <div>
                <label className="block mb-1 font-medium">Roll No.</label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  type="text"
                  {...register("roll_no_pg")}
                />
              </div>

              {/* University Registration No. */}
              <div>
                <label className="block mb-1 font-medium">
                  University Reg. No.
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  type="text"
                  {...register("regd_no_pg")}
                />
              </div>

              {/* CGPA */}
              <div>
                <label className="block mb-1 font-medium">CGPA</label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  type="number"
                  step="0.01" // Allows decimal values
                  {...register("pg_cgpa", {
                    min: {
                      value: 0,
                      message: "CGPA cannot be less than 0",
                    },
                    max: {
                      value: 10,
                      message: "CGPA cannot be more than 10",
                    },
                  })}
                />
                {errors.pg_cgpa && (
                  <p className="text-red-500">{errors.pg_cgpa.message}</p>
                )}
              </div>

              {/* Admission Year */}
              <div>
                <label className="block mb-1 font-medium">Admission Year</label>
                <select
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  {...register("year_of_addmission_pg")}
                >
                  <option value="">Select Admission Year</option>
                  {Array.from(
                    { length: 30 },
                    (_, i) => new Date().getFullYear() - i
                  ).map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>

              {/* Passout Year */}
              <div>
                <label className="block mb-1 font-medium">
                  Passout Year (PG)
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  type="text"
                  value={calculatePGPassoutYear()} // Display calculated passout year for PG
                  readOnly
                />
                <input
                  type="hidden"
                  {...register("pg_passout_year")}
                  value={calculatePGPassoutYear()}
                />
              </div>
            </div>
          </div>

          <div className="bg-white shadow-lg rounded-lg p-6">
            <h2 className="text-xl uppercase font-semibold text-gray-700 bg-gray-300 px-2 py-2 mb-6 border-b-2 pb-2">
              Other Details
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              <div>
                <label className="block mb-1 font-medium">
                  Family Annual Income <span className="text-red-500">*</span>
                </label>
                <select
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  {...register("family_income", { required: true })}
                >
                  <option value="">Select Income</option>
                  <option value="below 2 Lakh">{"<"} Below 2 Lakh</option>
                  <option value=">above 2 Lakh">{">"} Above 2 Lakh</option>
                </select>
                {errors.family_income && (
                  <p className="text-red-500">
                    Family Annual Income is required
                  </p>
                )}
              </div>
              {/* Profile Photo Upload */}
              <div>
                <label className="block mb-1 font-medium">
                  Upload Profile Photo <span className="text-red-500">*</span>
                </label>
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  className="w-full border border-gray-300 p-2 rounded-lg"
                  {...register("profile_pic", {
                    required: "Profile photo is required",
                    validate: {
                      fileType: (value) =>
                        (value &&
                          ["image/jpeg", "image/jpg", "image/png"].includes(
                            value[0]?.type
                          )) ||
                        "Invalid file type. Only JPG, JPEG, PNG are allowed.",
                      fileSize: (value) =>
                        (value && value[0]?.size <= 1024 * 1024) ||
                        "File size should be less than 1 MB.",
                    },
                  })}
                />
                {errors.profile_pic && (
                  <p className="text-red-500">{errors.profile_pic.message}</p>
                )}
              </div>
            </div>
          </div>

          <div>
            {/* Job/Professional Details Section */}
            {isAlumni === "1" && (
              <div className="bg-white shadow-lg rounded-lg p-6 mt-6">
                <h2 className="text-xl uppercase font-semibold text-gray-700 bg-gray-300 px-2 py-2 mb-6 border-b-2 pb-2">
                  Job/Professional Details
                </h2>

                {/* Are you got a job? */}
                <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
                  <label className="block mb-1 font-medium">
                    Have you got a job? <span className="text-red-500">*</span>
                  </label>
                  <div className="flex items-center">
                    <label className="mr-4">
                      <input
                        type="radio"
                        value="yes"
                        {...register("is_got_job", { required: true })}
                        onClick={() => setJobStatus("yes")}
                      />{" "}
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="no"
                        {...register("is_got_job", { required: true })}
                        onClick={() => setJobStatus("no")}
                      />{" "}
                      No
                    </label>
                  </div>
                  {errors.is_got_job && (
                    <p className="text-red-500">
                      Please select your job status
                    </p>
                  )}
                </div>

                {/* If 'Yes' is selected, show the company name, designation, and package fields */}
                {jobStatus === "yes" && (
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                    <div>
                      <label className="block mb-1 font-medium">
                        Company Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        type="text"
                        {...register("company_name", {
                          required: jobStatus === "yes",
                        })}
                      />
                      {errors.company_name && (
                        <p className="text-red-500">Company Name is required</p>
                      )}
                    </div>

                    <div>
                      <label className="block mb-1 font-medium">
                        Designation <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        type="text"
                        {...register("designation", {
                          required: jobStatus === "yes",
                        })}
                      />
                      {errors.designation && (
                        <p className="text-red-500">Designation is required</p>
                      )}
                    </div>

                    <div>
                      <label className="block mb-1 font-medium">
                        Annual Package <span className="text-red-500">*</span>
                      </label>
                      <select
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        {...register("annual_package", {
                          required: jobStatus === "yes",
                        })}
                      >
                        <option value="">Select Annual Package</option>
                        <option value="1-2 LPA">1-2 LPA</option>
                        <option value="2-4 LPA">2-4 LPA</option>
                        <option value="4-6 LPA">4-6 LPA</option>
                        <option value="6-8 LPA">6-8 LPA</option>
                        <option value="8-10 LPA">8-10 LPA</option>
                        <option value="10-12 LPA">10-12 LPA</option>
                        <option value="12-15 LPA">12-15 LPA</option>
                        <option value="15-20 LPA">15-20 LPA</option>
                        <option value="20 LPA and above">
                          20 LPA and above
                        </option>
                      </select>
                      {errors.annual_package && (
                        <p className="text-red-500">
                          Annual Package is required
                        </p>
                      )}
                    </div>
                  </div>
                )}

                {/* If 'No' is selected, show the reason options */}
                {jobStatus === "no" && (
                  <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
                    <label className="block mb-1 font-medium">
                      Reason for no job <span className="text-red-500">*</span>
                    </label>
                    <div className="flex flex-col">
                      <label>
                        <input
                          type="radio"
                          value="entrepreneur"
                          {...register("no_job_reason", {
                            required: jobStatus === "no",
                          })}
                          onClick={() => setReasonForNoJob("entrepreneur")}
                        />{" "}
                        Entrepreneur
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="higher_study"
                          {...register("no_job_reason", {
                            required: jobStatus === "no",
                          })}
                          onClick={() => setReasonForNoJob("higher_study")}
                        />{" "}
                        Higher Study
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="searching_job"
                          {...register("no_job_reason", {
                            required: jobStatus === "no",
                          })}
                          onClick={() => setReasonForNoJob("searching_job")}
                        />{" "}
                        Searching for Job
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="other"
                          {...register("no_job_reason", {
                            required: jobStatus === "no",
                          })}
                          onClick={() => setReasonForNoJob("other")}
                        />{" "}
                        Other
                      </label>
                    </div>

                    {/* Show text area for explanation only if "Other" is selected */}
                    {reasonForNoJob === "other" && (
                      <textarea
                        className="w-full border border-gray-300 p-2 rounded-lg mt-2"
                        placeholder="Please provide details"
                        {...register("no_job_details", {
                          required: reasonForNoJob === "other", // Only required if "Other" is selected
                          minLength: 10,
                        })}
                      ></textarea>
                    )}
                    {errors.no_job_details && (
                      <p className="text-red-500">
                        Please provide details (minimum 10 characters)
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>

          <Modal
            open={modalIsOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={style}>
              <h2 id="modal-title" className="text-lg font-semibold">
                Information PDF
              </h2>
              {pdfUrl && (
                <embed
                  src={pdfUrl}
                  type="application/pdf"
                  width="100%"
                  height="400px"
                />
              )}
              <button
                className="mt-4 bg-red-600 text-white py-2 px-4 rounded-lg"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </Box>
          </Modal>

          {/* <Modal
            open={modalIsOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={style}>
              <h2 id="modal-title" className="text-lg font-semibold">
                Information PDF
              </h2>
              {pdfUrl && (
                <>
                  <Document
                    file={pdfUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    options={{
                      workerSrc: `https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`,
                    }}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        scale={1} // Adjust scale to fit modal better
                        className="pdf-page" // Apply custom class for styling
                      />
                    ))}
                  </Document>
                </>
              )}
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseModal}
                className="mt-4"
              >
                Close
              </Button>
            </Box>
          </Modal> */}

          {/* Submit Button */}
          <div className="text-right">
            <button
              type="submit"
              className="bg-red-600 text-white py-2 px-4 rounded-lg flex items-center justify-center"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <LoadingIcon />
                  Updating Profile...
                </>
              ) : (
                "Update Profile"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PersonalDetailsForm;
