import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Tooltip,
  Link,
  Box,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InfoIcon from "@mui/icons-material/Info";
import { blue } from "@mui/material/colors";
import { getOngoingInternships } from "../../services/dataService";
import { Navigate, Link as RouterLink, useNavigate } from "react-router-dom";
// import PendingIcon from "@mui/icons-material/MoreHoriz";
// import InProgressIcon from "@mui/icons-material/DonutLarge";
// import CompleteIcon from "@mui/icons-material/CheckCircle";
//import { FiMoreHorizontal, FiUpload } from "react-icons/fi";
//import { FiLoader } from "react-icons/fi";
//import { FiCheckCircle } from "react-icons/fi";
import { MdAssessment, MdOutlinePending, MdPending } from "react-icons/md";
import { FaCircleCheck, FaCirclePause } from "react-icons/fa6";
import { Document, Page } from "react-pdf";
import PdfViewer from "./PDFViewer";
import googlepdf from "../../assets/Google AI-ML course process document.pdf";
import QuizIcon from "@mui/icons-material/Quiz";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LockIcon from "@mui/icons-material/Lock";
import HelpIcon from "@mui/icons-material/Help";
import CancelIcon from "@mui/icons-material/Cancel";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import pdf from "../../assets/Google AI-ML course process document.pdf";

import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

const importantDates = [
  { label: "Certificate Upload Start Date:", date: "20th October 2024" },
  { label: "Assessment Start Date:", date: "20th October 2024" },
  {
    label: "Internship Cert issue Start Date:",
    date: "1st December 2024",
  },

  //   { label: "Certificate Download Start Date:", date: "01 March 2024" },
  //   { label: "Certificate Download End Date:", date: "31 March 2024" },
];

const OngoingTable = () => {
  const navigate = useNavigate();
  //const [searchText, setSearchText] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [fetchedRows, setFetchedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState([]);
  const [domainHeader, setDomainHeader] = useState("");
  const [active, setActive] = useState([]);
  const [isData, setIsData] = useState([]);

  const [pdfUrl, setPdfUrl] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [numPages, setNumPages] = useState(null); // Track number of pages
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0); // Track the zoom level (scale)
  const [openRecordingDialog, setOpenRecordingDialog] = useState(false);
  const [selectedRecordings, setSelectedRecordings] = useState([]);
  const [isAfterTargetDate, setIsAfterTargetDate] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);

  const targetDate = new Date("2024-10-19");

  useEffect(() => {
    const currentDate = new Date();
    // Check if the current date is after the target date
    setIsAfterTargetDate(currentDate > targetDate);
  }, []);

  const handleInfoDialogOpen = () => {
    setOpenInfoDialog(true);
  };

  const handleInfoDialogClose = () => {
    setOpenInfoDialog(false);
  };

  const handlePdfOpen = (url) => {
    setPdfUrl(url);
    setIsDialogOpen(true);
  };

  const handlePdfClose = () => {
    setIsDialogOpen(false);
    setPdfUrl(null);
    setPageNumber(1);
    setScale(1.0); // Reset zoom level
  };

  const handleRecordingDialogOpen = (recordingString) => {
    // Extract all <a> tags using a regex and create an array of objects with `href` and `text` for each link
    const parser = new DOMParser();
    const doc = parser.parseFromString(recordingString, "text/html");
    const anchors = Array.from(doc.querySelectorAll("a"));

    const recordingsArray = anchors.map((anchor) => ({
      href: anchor.href, // Extract href attribute
      text: anchor.textContent, // Extract the text inside the <a> tag
    }));

    setSelectedRecordings(recordingsArray);
    setOpenRecordingDialog(true);
  };

  // Function to close the dialog
  const handleRecordingDialogClose = () => {
    setOpenRecordingDialog(false);
    setSelectedRecordings([]);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages); // Set the number of pages
  };

  // Handle next and previous page navigation
  const goToNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const goToPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const zoomIn = () => setScale(scale + 0.1); // Increase the scale
  const zoomOut = () => setScale(scale > 0.5 ? scale - 0.1 : scale); // Decrease the scale, but not below 0.5

  useEffect(() => {
    const fetchOngoingInternshipData = async () => {
      try {
        setLoading(true);
        const response = await getOngoingInternships.ongoingInternshipsData();
        if (response.data && response.data.internships) {
          const data = response.data.internships;
          setIsData(data);
          //console.log("ongoing", data);

          setSelectedCertificate(data[0].certificate_status || []);
          setActive(data[0].assessment_active);
          const rowsWithId = data.map((row, index) => ({
            ...row,
            id: row.sl_no || index,
          }));
          setFetchedRows(rowsWithId);
          setFilteredRows(rowsWithId);
          setDomainHeader(data[0].domain); // Assuming the domain is the same for all rows
        } else {
          throw new Error("Invalid response structure");
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchOngoingInternshipData();
  }, []);

  //   const handleSearch = (searchValue) => {
  //     setSearchText(searchValue);
  //     if (searchValue === "") {
  //       setFilteredRows(fetchedRows);
  //     } else {
  //       setFilteredRows(
  //         fetchedRows.filter((row) =>
  //           Object.keys(row).some((field) =>
  //             row[field]
  //               .toString()
  //               .toLowerCase()
  //               .includes(searchValue.toLowerCase())
  //           )
  //         )
  //       );
  //     }
  //   };

  //   const clearSearch = () => {
  //     setSearchText("");
  //     setFilteredRows(fetchedRows);
  //   };

  const statusIcons = {
    "Not Uploaded": (
      <MdPending style={{ color: blue[500], fontSize: "26px" }} />
    ),
    Uploaded: <FaCirclePause style={{ color: "orange", fontSize: "24px" }} />,
    Verified: <FaCircleCheck style={{ color: "green", fontSize: "24px" }} />,
  };

  const handleDialogOpen = (certificateNumber, certificateStatus) => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  // const getButtonProps = (assessment_active) => {
  //   switch (assessment_active) {
  //     case null:
  //       return {
  //         color: "default", // Gray
  //         text: "Assessment",
  //         disabled: true,
  //         icon: <HelpIcon />,
  //       };
  //     case 0:
  //       return {
  //         color: "success", // Green
  //         text: "Assessment",
  //         disabled: false,
  //         icon: <QuizIcon />,
  //       };
  //     case 1:
  //       return {
  //         color: "success", // Green
  //         text: "Completed",
  //         disabled: false,
  //         icon: <CheckCircleIcon />,
  //       };
  //     case 3:
  //       return {
  //         color: "error", // Red
  //         text: "Restricted",
  //         disabled: true,
  //         icon: <LockIcon />,
  //       };
  //     default:
  //       return {
  //         color: "default", // Default color
  //         text: "Assessment",
  //         disabled: true,
  //         icon: <HelpIcon />,
  //       };
  //   }
  // };

  // const { color, text, disabled, icon } = getButtonProps(active);

  if (loading) {
    return (
      <Box sx={{ width: "100%", marginTop: 3 }}>
        <Skeleton height={40} />
        <Skeleton height={40} />
        <Skeleton height={40} />
      </Box>
    );
  }

  const getButtonProps = (assessment_active) => {
    switch (assessment_active) {
      case null:
        return {
          color: "success", // Gray
          text: "Not Applicable",
          disabled: true,
          icon: <CancelIcon />, // Cross icon
          // sx: {
          //   backgroundColor: "red",
          //   color: "white",
          //   "&.Mui-disabled": {
          //     backgroundColor: "red",
          //     color: "white",
          //   },
          //   "&:hover": {
          //     backgroundColor: "darkred",
          //   },
          // }, // Default styles
        };
      case "0":
        return {
          color: "success", // Default color
          text: "Start Assessment",
          disabled: false,
          icon: <QuizIcon />,
          sx: {}, // Default styles
        };
      case "1":
        return {
          color: "success", // Green
          text: "Assessment Completed",
          disabled: false,
          icon: <CheckCircleIcon />,
          sx: {}, // Default styles
        };
      case "4":
        return {
          color: "primary", // Green
          text: "Assessment",
          disabled: true,
          icon: <QuizIcon />,
          sx: {}, // Default styles
        };
      case "3":
        return {
          color: "error", // Red
          text: "Assessment Restricted",
          disabled: true,
          icon: <LockIcon />,
          sx: {
            backgroundColor: "red",
            color: "white",
            "&.Mui-disabled": {
              backgroundColor: "red",
              color: "white",
            },
            "&:hover": {
              backgroundColor: "darkred",
            },
          },
        };
      default:
        return {
          color: "primary", // Default color
          text: "Assessment",
          disabled: true,
          icon: <HelpIcon />,
          sx: {}, // Default styles
        };
    }
  };

  if (isData.length === 0) {
    return (
      <div className="flex items-center justify-center px-8 py-8 bg-gray-100">
        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg p-6 text-center">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">
            Loading...
          </h1>
        </div>
      </div>
    );
  }

  return (
    <Container sx={{ marginTop: "50px" }}>
      <Typography
        variant="h4"
        sx={{ fontWeight: "800" }}
        component="h2"
        gutterBottom
      >
        In-Progress
      </Typography>
      <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
        <Grid item xs={12} sm="auto">
          {/* <TextField
            variant="outlined"
            size="small"
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search..."
            InputProps={{
              endAdornment: searchText && (
                <InputAdornment position="end">
                  <IconButton onClick={clearSearch}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          /> */}
        </Grid>
      </Grid>
      {loading ? (
        <Box sx={{ width: "100%", marginTop: 3 }}>
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
        </Box>
      ) : (
        <Box sx={{ width: "100%", marginTop: 3 }}>
          {/* <Typography
            variant="h5"
            sx={{ fontWeight: "700", textAlign: "center" }}
            component="h3"
            gutterBottom
          >
            {domainHeader}
          </Typography> */}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#1E88E5" }}>
                  <TableCell colSpan={5} sx={{ color: "white" }}>
                    INTERNSHIP DOMAIN : {domainHeader}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.map((row) => (
                  <TableRow key={row.id}>
                    {(row.domain_id === 26 ||
                      row.domain_id === 27 ||
                      row.domain_id === 28) && (
                      <TableCell>
                        <Tooltip title="LMS">
                          <Link
                            href="https://lms.eduskills.academy"
                            target="_blank"
                            rel="noopener noreferrer"
                            color="primary"
                            sx={{
                              textDecoration: "none",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <MdAssessment
                              style={{
                                fontSize: "24px",
                                color: blue[500],
                                marginRight: "8px",
                              }}
                            />
                            Course Access Link
                          </Link>
                        </Tooltip>
                      </TableCell>
                    )}
                    <TableCell>
                      {row.process_document !== "#" && (
                        <Tooltip title="View Document">
                          <Link
                            // href={row.process_document}
                            // target="_blank"
                            onClick={() =>
                              handlePdfOpen(
                                "https://aicteinternshipcertstore.s3.amazonaws.com/299219-11.pdf"
                              )
                            }
                            sx={{
                              textDecoration: "none",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <PictureAsPdfIcon
                              sx={{ color: "red", marginRight: 1 }}
                            />
                            Course Access Document
                          </Link>
                        </Tooltip>
                      )}
                    </TableCell>

                    <TableCell>
                      {/* Show the recording links icon and text if recordings are available */}
                      {row.record_link && row.record_link.length > 0 && (
                        <Tooltip title="View Recording Links">
                          <Link
                            onClick={() =>
                              handleRecordingDialogOpen(row.record_link)
                            }
                            sx={{
                              textDecoration: "none",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              color: blue[500], // You can style the color as needed
                            }}
                          >
                            <VideoLibraryIcon sx={{ marginRight: 1 }} />
                            Recorded Session Links
                          </Link>
                        </Tooltip>
                      )}
                    </TableCell>
                    {row.domain_id !== 26 &&
                      row.domain_id !== 27 &&
                      row.domain_id !== 28 && (
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip
                              title={`${
                                !isAfterTargetDate
                                  ? "Upload not Available"
                                  : "Certificate Upload"
                              }`}
                            >
                              <Link
                                //href="https://internshipadmin.eduskillsfoundation.org/"
                                //href="https://66ceb19088b5caf41ab43af8--eduskillsfoundationundermentenance.netlify.app/"
                                target="_blank"
                                rel="noopener noreferrer"
                                color="primary"
                                sx={{
                                  textDecoration: "none",
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: isAfterTargetDate
                                    ? "pointer"
                                    : "not-allowed",
                                  color: isAfterTargetDate ? "black" : "gray",
                                  marginRight: 2, // Add some margin between the link and the circular progress
                                }}
                                //onClick={() => navigate("/cert-upload")}
                                onClick={() =>
                                  isAfterTargetDate && navigate("/cert-upload")
                                }
                              >
                                <UploadIcon
                                  sx={{
                                    marginRight: 1,
                                    color: `${
                                      isAfterTargetDate ? "blue" : "gray"
                                    }`,
                                  }}
                                />
                                Certificate Upload
                              </Link>
                            </Tooltip>

                            {/* <Tooltip title={"Certificate Status"}>
                              <Box
                                sx={{
                                  position: "relative",
                                  display: "inline-flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <CircularProgress
                                  variant="determinate"
                                  value={row.percent}
                                  size={40}
                                  thickness={5}
                                  sx={{ color: "blue" }}
                                  disabled={!isAfterTargetDate}
                                />
                                <Box
                                  sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: "absolute",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    isAfterTargetDate
                                      ? handleDialogOpen(
                                          row.certificateNumber,
                                          row.certificate_status
                                        )
                                      : handleInfoDialogOpen()
                                  }
                                >
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    color={
                                      isAfterTargetDate
                                        ? "textSecondary"
                                        : "gray"
                                    }
                                    fontWeight="800"
                                  >{`${Math.round(row.percent)}%`}</Typography>
                                </Box>
                              </Box>
                            </Tooltip> */}
                          </Box>
                        </TableCell>
                      )}
                    <TableCell>
                      <Tooltip
                        title={getButtonProps(row.assessment_active).text}
                      >
                        <Button
                          variant="contained"
                          color={getButtonProps(row.assessment_active).color}
                          startIcon={getButtonProps(row.assessment_active).icon}
                          disabled={
                            getButtonProps(row.assessment_active).disabled
                          }
                          sx={getButtonProps(row.assessment_active).sx} // Apply custom styles
                          onClick={() => navigate("/assessment")}
                        >
                          {getButtonProps(row.assessment_active).text}
                        </Button>
                      </Tooltip>
                    </TableCell>

                    {/* <TableCell>
                      <Tooltip title="Certificate Download">
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<DownloadIcon />}
                          disabled={!row.certificate_download_status}
                          onClick={() =>
                            window.open(
                              "https://aicte-internship.eduskillsfoundation.org/pages/home/",
                              "_blank"
                            )
                          }
                        >
                          Certificate Download
                        </Button>
                      </Tooltip>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Note Card */}
          <Card sx={{ padding: 1 }}>
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", marginRight: 2 }}
                >
                  Note
                  <Typography
                    component="span"
                    sx={{ color: "red", fontWeight: "bold" }}
                  >
                    {" *"}
                  </Typography>
                  :
                </Typography>
                <Typography variant="body1" sx={{ flex: 1, fontWeight: "600" }}>
                  Before accessing the course, please go through the course
                  access document thoroughly.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}
      <Card className="overflow-hidden p-2">
        <CardContent>
          <Box>
            <Grid container spacing={0}>
              {Array.from({ length: 3 }).map((_, gridIndex) => {
                const start = Math.ceil(importantDates.length / 3) * gridIndex;
                const end =
                  Math.ceil(importantDates.length / 3) * (gridIndex + 1);
                const datesSlice = importantDates.slice(start, end);

                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={gridIndex}
                    className="p-1"
                  >
                    <Table className="w-full">
                      <TableBody>
                        {datesSlice.map((date, index) => (
                          <TableRow
                            key={index}
                            className={`${
                              index % 2 === 0 ? "bg-white" : "bg-gray-100"
                            }`}
                          >
                            <TableCell
                              align="center"
                              sx={{ fontWeight: "bold",fontSize:"10px" }}
                              // className="font-bold border-b border-gray-300 p-1 text-2xl leading-tight"
                            >
                              {date.label}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ fontSize: "10px" }}
                              className="border-b border-gray-300 p-1 text-sm leading-tight"
                            >
                              {date.date}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </CardContent>
      </Card>

      <Dialog open={openInfoDialog} onClose={handleInfoDialogClose}>
        <DialogTitle>Not Available Yet</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            The Certificate Upload and progress tracking will be available after
            20th October 2024. Please come back after that date.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleInfoDialogClose}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog to show recording links */}
      <Dialog
        open={openRecordingDialog}
        onClose={handleRecordingDialogClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ backgroundColor: blue[500], color: "#fff" }}>
          Recording Links
        </DialogTitle>
        <DialogContent>
          <Table sx={{ marginTop: 2, boxShadow: 3 }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: blue[100] }}>
                {/* <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderBottom: "2px solid #ddd" }}
                >
                  Session Name
                </TableCell> */}
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderBottom: "2px solid #ddd" }}
                >
                  Sessions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedRecordings.length > 0 ? (
                selectedRecordings.map((recording, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:nth-of-type(even)": { backgroundColor: "#f5f5f5" },
                      "&:nth-of-type(odd)": { backgroundColor: "#ffffff" },
                      "&:hover": { backgroundColor: "#e0e0e0" },
                    }}
                  >
                    {/* <TableCell
                      align="center"
                      sx={{ borderBottom: "1px solid #ddd" }}
                    >
                      {`Session ${index + 1}`}
                    </TableCell> */}
                    <TableCell
                      align="center"
                      sx={{ borderBottom: "1px solid #ddd" }}
                    >
                      {/* Render the link using the parsed href and text */}
                      <Link
                        href={recording.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ color: blue[500], textDecoration: "none" }}
                      >
                        {recording.text}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={2}
                    align="center"
                    sx={{ color: "#888", padding: "16px" }}
                  >
                    No recordings available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleRecordingDialogClose}
            variant="contained"
            color="secondary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle sx={{ backgroundColor: blue[500], color: "#fff" }}>
          Certificate Status
        </DialogTitle>
        <DialogContent>
          <Table sx={{ marginTop: 2, boxShadow: 3 }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: blue[100] }}>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderBottom: "2px solid #ddd" }}
                >
                  Course Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderBottom: "2px solid #ddd" }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedCertificate.length > 0 ? (
                selectedCertificate.map((cert, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:nth-of-type(even)": { backgroundColor: "#f5f5f5" },
                      "&:nth-of-type(odd)": { backgroundColor: "#ffffff" },
                      "&:hover": { backgroundColor: "#e0e0e0" },
                    }}
                  >
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: "1px solid #ddd",
                        paddingRight: "50px",
                      }}
                    >
                      {cert.course_name}
                    </TableCell>
                    <Tooltip title={cert.status}>
                      <TableCell
                        align="center"
                        sx={{
                          borderBottom: "1px solid #ddd",
                          paddingLeft: "50px",
                        }}
                      >
                        {statusIcons[cert.status]}
                      </TableCell>
                    </Tooltip>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={2}
                    align="center"
                    sx={{ color: "#888", padding: "16px" }}
                  >
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            // onClick={() =>
            //   window.open(
            //     "https://internshipadmin.eduskillsfoundation.org/certupload",
            //     "_blank"
            //   )
            // }
            onClick={() => navigate("/cert-upload")}
            variant="contained"
            color="info"
          >
            GO TO CERTIFICATE UPLOAD
          </Button>
          <Button onClick={handleDialogClose} variant="contained" color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Course Access Document</DialogTitle>
        <DialogContent>
          {pdfUrl && (
            <Document
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(error) =>
                console.error("Error loading PDF:", error)
              }
            >
              <Page pageNumber={pageNumber} scale={scale} />
            </Document>
          )}
        </DialogContent>
        <DialogActions>
          {/* Zoom controls */}
          <Button onClick={zoomOut} variant="contained" color="secondary">
            Zoom Out
          </Button>
          <Button onClick={zoomIn} variant="contained" color="secondary">
            Zoom In
          </Button>
          {/* Previous and Next buttons for page navigation */}
          <Button
            onClick={goToPreviousPage}
            variant="contained"
            color="primary"
            disabled={pageNumber <= 1} // Disable when on the first page
          >
            Previous
          </Button>
          <Typography sx={{ margin: "0 10px" }}>
            Page {pageNumber} of {numPages}
          </Typography>
          <Button
            onClick={goToNextPage}
            variant="contained"
            color="primary"
            disabled={pageNumber >= numPages} // Disable when on the last page
          >
            Next
          </Button>
          <Button
            onClick={handlePdfClose}
            variant="contained"
            color="secondary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default OngoingTable;
